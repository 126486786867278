import React, { useEffect, useState } from 'react';
import { Col, Row, Form, Button, notification, InputNumber, DatePicker, Select, Spin } from 'antd';
import { CloseCircleOutlined ,PlusCircleOutlined} from "@ant-design/icons";
import moment from 'moment';
import Card from '../../components/Card';
import Text from '../../components/Text';
import financialsApi from '../../Api/Financials';
import '../../../node_modules/moment/locale/tr';
import locale from '../../../node_modules/antd/es/date-picker/locale/tr_TR';

const DailyInterestRates: React.VFC = () => {

  const [form] = Form.useForm();
  const [buyers, setBuyers] = useState([]);
  const [buyersId, setBuyersId] = useState('');
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dailyRatesData, setDailyRatesData] = useState<any>([]);
  const [data, setData] = useState<any>([]);
  const [isShowRow, setIsShowRow] = useState<any>([]);
  const [expiryDate, setExpiryDate] = useState<any>()

  const getBuyers = async () => {
    const response = await financialsApi.getBuyerLists();
    if (response) {
      setBuyers(response.data.buyers.data.filter((item: any) => item.isDefinedForFinancialInstitution !== false))
    }
  }

  const getInterestRates = async (value: string) => {
    setLoading(true);
    try{
    const response = await financialsApi.fetchInterestRates(value);
    if (response) {
      const dataRates:any=[]
      response.data.rates.map((item: any) => {
        return (
          dataRates.push(
            {
              maturityStart: item.maturityStart,
              maturityEnd: item.maturityEnd,
              interestRate: item.interestRate,
              commissionRate: item.commissionRate,
              expiryDate: item.expiryDate
            }
          )
        )
      });
      setDailyRatesData(dataRates)
      setData(dataRates)
      setLoading(false);
    }
    }catch(e:any){
      // console.log('hata',e)
    }
  }

  const disabledDate = (current: any) => {
    return (
      current < Date.now() ||
      (new Date(current).getDay() === 0 ||
        new Date(current).getDay() === 6)
    );
  }

  const handleFormChange = (index:any, field:any, event:any) => {
    dailyRatesData[index][field] = event === null ? data[index][field]  : event;
  }

  const handleFormChangeDate = (field:any, event:any) => {
    setExpiryDate(event)
    dailyRatesData.map((item:any, index:any) => {
      dailyRatesData[index][field] = event === null ? item[index][field]  : event 
      return dailyRatesData
    })
  }

  const deleteData = async (index:any) =>{
    setDailyRatesData(dailyRatesData.filter((item:any, i:any) => i !== index ))
    form.resetFields();
  }

  const getDataRates =  async () => {
    const rates:any= [];
    try {
      setLoading(true)
      dailyRatesData.map((item:any) => {
       return(
         rates.push({
           maturityStart: item.maturityStart ,
           maturityEnd:  item.maturityEnd,
           interestRate:  item.interestRate ,
           commissionRate: item.commissionRate,
           expiryDate: dailyRatesData.some((it:any) => !it.expiryDate) ?  expiryDate : item.expiryDate,
         })
       )
     })

     const getData :any = {
       buyerId:buyersId,rates
     }
 
      const response = await financialsApi.setInterestRate(getData);
      if (response) {
        setLoading(false)
        await getInterestRates(buyersId);
        // getInterestRates(buyersId)
        form.resetFields();
        notification.success({
          message: 'Oranlarınız Başarıyla Güncellendi.',
          style:{minHeight:'80px'}
        });
      }
    } catch (e: any) {
      setLoading(false)
      if (e.status === 406) {
        notification.error({
          message:  e.data.Title,
        });
      }
      if (e.status === 400) {
        notification.error({
          message: 'Alanları boş bıraktınız. Lütfen 0-100 arası bir değer giriniz.',
        });
      }
    }
   
  };

  const handleChange = (value: string) => {
    form.resetFields();
    if (value) {
      setBuyersId(value);
      setShow(true);
      getInterestRates(value);
    }
  };

  const showForm = () => {
    setIsShowRow([...isShowRow, {maturityStart: '', maturityEnd: '', interestRate:'', commissionRate:'', expiryDate:''}])
    const deger = dailyRatesData[dailyRatesData.length-1]?.maturityEnd === '' ? '' :  dailyRatesData[dailyRatesData.length-1]?.maturityEnd +1;
    dailyRatesData[dailyRatesData.length] = { maturityStart: deger, maturityEnd: '',interestRate: '', commissionRate:'', expiryDate:'' }
  }


useEffect(() => {
  if(dailyRatesData.length <1){
    dailyRatesData[dailyRatesData.length] = { maturityStart: 0 , maturityEnd: '',interestRate: '', commissionRate:'', expiryDate:'' }
  }
}, [dailyRatesData])

  useEffect(() => {
    getBuyers();
  }, [])

  return (
    <>
      <Card>
        <Row gutter={20} align="middle">
          <Col>
            <Text className="m-0 page-title" type="h1" color="primary" bold>
              Faiz Oranları
            </Text>
          </Col>
        </Row>
        <Row>
          <Col style={{ margin: '30px auto 10px' }}>
            <p>Lütfen güncellemek istediğiniz alıcıyı seçiniz.</p>
            <Select
              defaultValue="Lütfen seçim yapınız."
              style={{ width: 400 }}
              onChange={handleChange}
              options={buyers.map((item: any) => {
                return (
                  { value: item.id, label: item.title }
                )
              })
              }
            />
          </Col>
        </Row>
        <Spin spinning={loading}>
          {
            show ? (
              <div style={{display:'flex', flexDirection:'column'}}>
            
                <Row>
                  <Col style={{ margin: '20px auto 20px' }}>
                    <Text className="m-0" type="h4" bold>
                      Günlük Faiz ve Komisyon Oranlarını Ondalık Kısım İçin &#8216;.&#8216; Kullanarak Giriniz.
                    </Text>
                  </Col>
                </Row>

              
                <Row>
                  <Col span={22} style={{ margin: '0 auto', textAlign: 'center' }} className='interest-rates-box' >
                  <div className='rates-row rates-row-title'>
                      <div>
                        <span>Vade <br /> Başlangıç <br />Günü </span> 
                      </div>
                      <div>
                        <span>Vade <br /> Bitiş <br /> Günü</span>
                      </div>
                      <div>
                        <span>Faiz <br /> Oranı %</span>
                      </div>
                      <div>
                        <span>Komisyon <br /> Oranı %</span>
                      </div>
                      {/* <div>
                        <span>Geçerlilik <br /> Tarihi</span>
                      </div> */}
                      <div><span> </span></div>
                    </div>
                    <Form
                      form={form}
                      name="changePassword"
                      autoComplete="off"
                      labelCol={{
                        span: 8,
                      }}
                      size="large"
                      style={{
                        minWidth: 400,
                      }}
                    >
                  
                      {
                       dailyRatesData.map((item: any, index:any) => {
                          return (
                            <div className='rates-row' key={index}>
                              <div className='int-col'>
                                <div className='int-box'>
                                  <Form.Item   name={`maturityStart${index}`}>
                                    <InputNumber
                                      className='input-width no-spinner'
                                      width='60px'
                                      min={0}
                                      max={270}
                                      formatter={(value) => `${value}`}
                                      placeholder={item.maturityStart === null ?  0 : item.maturityStart }
                                      step={1}
                                      precision={0} 
                                      onChange={event => handleFormChange(index,'maturityStart', event)}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className='int-col'>
                                <div className='int-box'>
                                  <Form.Item  name={`maturityEnd${index}`}>
                                    <InputNumber
                                     
                                      className='input-width no-spinner'
                                      width='60px'
                                      min={0}
                                      max={270}
                                      formatter={(value) => `${value}`}
                                      placeholder={item.maturityEnd === null ?  0 : item.maturityEnd }
                                      step={1}
                                      precision={0} 
                                      onChange={event => handleFormChange(index, 'maturityEnd',event)}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className='int-col'>
                                <div className='int-box'>
                                  <Form.Item name={`interestRate${index}`}>
                                    <InputNumber
                                      className='input-width no-spinner'
                                      width='60px'
                                      min={0}
                                      max={100}
                                      formatter={(value) => `${value}`}
                                      parser={(value: any) => value.replace('%', '')}
                                      placeholder={item.interestRate === null ?  0 : item.interestRate}
                                      step={1}
                                      onChange={event => handleFormChange(index, 'interestRate',event)}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              <div className='int-col'>
                                <div className='int-box'>
                                  <Form.Item name={`commissionRate${index}`} >
                                    <InputNumber
                                      className='input-width no-spinner'
                                      width='60px'
                                      min={0}
                                      max={100}
                                      formatter={(value) => `${value}`}
                                      parser={(value: any) => value.replace('%', '')}
                                      placeholder={item.commissionRate === null ?  0 : item.commissionRate}
                                      step={1}
                                      onChange={event => handleFormChange(index, 'commissionRate',event)}
                                    />
                                  </Form.Item>
                                </div>
                              </div>
                              {/* <div className='date-row'>
                                <Form.Item  name={`expiryDate${index}`} >
                                  <DatePicker 
                                    placeholder={dailyRatesData[index].expiryDate !== '' ? moment(dailyRatesData[index].expiryDate).format('DD/MM/YYYY') : 'Tarih seç'} 
                                    locale={locale} format='DD/MM/YYYY' 
                                    disabledDate={disabledDate} 
                                    onChange={event => handleFormChange(index, 'expiryDate',event)}
                                    />
                                </Form.Item>
                              </div> */}
                              <div>
                                {
                                  dailyRatesData.length !== 1 ? (
                                    <CloseCircleOutlined 
                                  className='daily-rate-delete-btn'
                                   onClick={() => deleteData(index)}   
                                   />
                                  ):
                                  ''
                                }
                                
                              </div>
                            </div>
                          )
                        })
                      }
                     <div className='rates-row rates-row-btn'>
                       <span style={{fontWeight:'bold'}}>Faizlerin Geçerlilik Tarihi</span>
                        <div className='date-row' style={{width:'auto', paddingLeft:'10px'}} >
                          
                          <Form.Item name='expiryDate' >
                            <DatePicker
                              placeholder={
                                  dailyRatesData.length < 1 ? 'Tarih seç' : 
                                  dailyRatesData[0].expiryDate !== '' ? moment(dailyRatesData[0].expiryDate).format('DD/MM/YYYY') : 'Tarih seç'
                                }
                              locale={locale} format='DD/MM/YYYY'
                              disabledDate={disabledDate}
                              onChange={event => handleFormChangeDate('expiryDate', event)}
                            />
                          </Form.Item>
                        </div>
                     </div>
                    <div className='rates-row rates-row-btn' >
                        <Form.Item>
                          <Button
                            onClick={getDataRates}
                            type="primary"
                            block
                          >Onayla</Button>
                        </Form.Item>
                      </div>
                    </Form>
                  </Col>
                  <Col span={2} style={{display:'flex', alignItems:'end'}}>
                  <div>
                    <PlusCircleOutlined  className='daily-rate-add-btn' onClick={showForm} />
                   </div>
                </Col>
                </Row>
              
               
               
              </div>
            ) :
              (
                ''
              )
          }
        </Spin>
      </Card>
      {/* <Modal
        title="Faiz Değişikliği Onayı"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Onayla"
        cancelText="İptal"
      >
        <p>Gerçekleştirmiş olduğunuz değişiklikler aşağıda yer almaktadır.<br /> Onaylıyor musunuz?</p>
        <p><strong>Alıcı:</strong> {buyersTitle && buyersTitle[0]?.title}</p>
        <p className={formData.oneDayInterestRate !== undefined && formData.oneDayInterestRate !== null ||
          formData.oneDayCommissionRate !== undefined && formData.oneDayCommissionRate !== null ||
          formData.oneDayDate !== undefined && formData.oneDayDate !== null ? 'marginBottom' : ''}>

          <div className={formData.oneDayInterestRate !== undefined && formData.oneDayInterestRate !== null ? 'new-line' : ''}>
            {formData.oneDayInterestRate !== undefined && formData.oneDayInterestRate !== null ? `0-30 Gün Vade Faiz:  ${formData.oneDayInterestRate}% ` : ''}
          </div>
          <div className={formData.oneDayCommissionRate !== undefined && formData.oneDayCommissionRate !== null ? 'new-line' : ''}>
            {formData.oneDayCommissionRate !== undefined && formData.oneDayCommissionRate !== null ? `0-30 Gün Vade Komisyon:  ${formData.oneDayCommissionRate}%` : ''}
          </div>
          <div className={formData.oneDayDate !== undefined && formData.oneDayDate !== null ? 'new-line' : ''}>
            {formData.oneDayDate !== undefined && formData.oneDayDate !== null ? `0-30 Geçerlilik Tarihi: ${moment(formData.oneDayDate).format('DD/MM/YYYY')}` : ''}
          </div>
        </p>
        <p className={formData.twoDayInterestRate !== undefined && formData.twoDayInterestRate !== null
          || formData.twoDayCommissionRate !== undefined && formData.twoDayCommissionRate !== null
          || formData.twoDayDate !== undefined && formData.twoDayDate !== null ? 'marginBottom' : ''}>
          <div className={formData.twoDayInterestRate !== undefined && formData.twoDayInterestRate !== null ? 'new-line' : ''}>
            {formData.twoDayInterestRate !== undefined && formData.twoDayInterestRate !== null ? `31-60 Gün Vade Faiz:  ${formData.twoDayInterestRate}% ` : ''}
          </div>
          <div className={formData.twoDayCommissionRate !== undefined && formData.twoDayCommissionRate !== null ? 'new-line' : ''}>
            {formData.twoDayCommissionRate !== undefined && formData.twoDayCommissionRate !== null ? `31-60 Gün Vade Komisyon:  ${formData.twoDayCommissionRate}%` : ''}
          </div>
          <div className={formData.twoDayDate !== undefined && formData.twoDayDate !== null ? 'new-line' : ''}>
            {formData.twoDayDate !== undefined && formData.twoDayDate !== null ? `31-60 Geçerlilik Tarihi: ${moment(formData.twoDayDate).format('DD/MM/YYYY')}` : ''}
          </div>
        </p>

        <p className={formData.threeDayInterestRate !== undefined || formData.threeDayCommissionRate !== undefined || formData.threeDayDate !== undefined ? 'marginBottom' : ''}>
          <div className={formData.threeDayInterestRate !== undefined ? 'new-line' : ''}>
            {formData.threeDayInterestRate !== undefined ? `61-90 Gün Vade Faiz:  ${formData.threeDayInterestRate}% ` : ''}
          </div>
          <div className={formData.threeDayCommissionRate !== undefined ? 'new-line' : ''}>
            {formData.threeDayCommissionRate !== undefined ? `61-90 Gün Vade Komisyon:  ${formData.threeDayCommissionRate}%` : ''}
          </div>
          <div className={formData.threeDayDate !== undefined ? 'new-line' : ''}>
            {formData.threeDayDate !== undefined ? `61-90 Geçerlilik Tarihi: ${moment(formData.threeDayDate).format('DD/MM/YYYY')}` : ''}
          </div>
        </p>
        <p className={formData.fourDayInterestRate !== undefined || formData.fourDayCommissionRate !== undefined || formData.fourDayDate !== undefined ? 'marginBottom' : ''}>

          <div className={formData.fourDayInterestRate !== undefined ? 'new-line' : ''}>
            {formData.fourDayInterestRate !== undefined ? `91-120 Gün Vade Faiz:  ${formData.fourDayInterestRate}% ` : ''}
          </div>
          <div className={formData.fourDayCommissionRate !== undefined ? 'new-line' : ''}>
            {formData.fourDayCommissionRate !== undefined ? `91-120 Gün Vade Komisyon:  ${formData.fourDayCommissionRate}%` : ''}
          </div>
          <div className={formData.fourDayDate !== undefined ? 'new-line' : ''}>
            {formData.fourDayDate !== undefined ? `91-120 Geçerlilik Tarihi: ${moment(formData.fourDayDate).format('DD/MM/YYYY')}` : ''}
          </div>
        </p>
        <p className={formData.fiveDayInterestRate !== undefined || formData.fiveDayCommissionRate !== undefined || formData.fiveDayDate !== undefined ? 'marginBottom' : ''}>

          <div className={formData.fiveDayInterestRate !== undefined ? 'new-line' : ''}>
            {formData.fiveDayInterestRate !== undefined ? `121-180 Gün Vade Faiz:  ${formData.fiveDayInterestRate}% ` : ''}
          </div>
          <div className={formData.fiveDayCommissionRate !== undefined ? 'new-line' : ''}>
            {formData.fiveDayCommissionRate !== undefined ? `121-180 Gün Vade Komisyon:  ${formData.fiveDayCommissionRate}%` : ''}
          </div>
          <div className={formData.fiveDayDate !== undefined ? 'new-line' : ''}>
            {formData.fiveDayDate !== undefined ? `121-180 Geçerlilik Tarihi: ${moment(formData.fiveDayDate).format('DD/MM/YYYY')}` : ''}
          </div>
        </p>
        <p className={formData.sixDayInterestRate !== undefined || formData.sixDayCommissionRate !== undefined || formData.sixDayDate !== undefined ? 'marginBottom' : ''}>

          <div className={formData.sixDayInterestRate !== undefined ? 'new-line' : ''}>
            {formData.sixDayInterestRate !== undefined ? `181-270 Gün Vade Faiz:  ${formData.sixDayInterestRate}% ` : ''}
          </div>
          <div className={formData.sixDayCommissionRate !== undefined ? 'new-line' : ''}>
            {formData.sixDayCommissionRate !== undefined ? `181-270 Gün Vade Komisyon:  ${formData.sixDayCommissionRate}%` : ''}
          </div>
          <div className={formData.sixDayDate !== undefined ? 'new-line' : ''}>
            {formData.sixDayDate !== undefined ? `181-270 Geçerlilik Tarihi: ${moment(formData.sixDayDate).format('DD/MM/YYYY')}` : ''}
          </div>

          {

          }
        </p>

      </Modal> */}
    </>
  );
};

export default DailyInterestRates;
